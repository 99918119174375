import {
  provide,
  computed,
  type InjectionKey,
  inject,
  ref,
  type Ref,
  type ComputedRef,
} from "vue"
import { useApiFetchWithInitial } from "stella"
import { formatDateFull, formatDateMedium } from "@justworkshr/alma/format"
import {
  type CompanyBenefitsApi,
  type MemberDetails,
  type MemberEnrollmentAPI,
  type QuoteStatuses,
  type CompanyEnrollment,
  BenefitType,
  type ProviderPlan,
  type CustomBenefit,
  type UpsOverview,
} from "@/types"
import {
  CompanyBenefitsFetchSymbol,
  companyBenefitsFetchDefault,
  memberEnrollmentsFetchDefault,
  MemberEnrollmentsFetchSymbol,
  memberDetailsFetchDefault,
  MemberDetailsFetchSymbol,
  CompanyQuotesFetchSymbol,
  companyQuotesFetchDefault,
  UnifiedPlanSelectionEnabledSymbol,
  type UnifiedPlanSelectionEnabledPayload,
  unifiedPlanSelectionEnabledDefault,
  UnifiedPlanSelectionOverviewSymbol,
} from "@/symbols"
import { type Route } from "@/components/nextBen/Task.vue"
import { viewer } from "@/viewer"

type TaskData = {
  id: string
  title: string
  description?: string
  route: Route
  buttonLabel?: string
}

export type AdminBenefitsDashboardCompanyBenefitData = {
  benefitType: BenefitType
  enrollmentStartDate: string
  providerName: string
  providerPlans: ProviderPlan[]
  numberOfEnrolledMembers?: number
}

// Symbol for the Company Benefits Store is defined in this file
const AdminBenefitsDashboardStoreSymbol = Symbol() as InjectionKey<{
  data: {
    healthBenefits: ComputedRef<AdminBenefitsDashboardCompanyBenefitData[]>
    wealthBenefits: ComputedRef<AdminBenefitsDashboardCompanyBenefitData[]>
    lifeBenefits: ComputedRef<AdminBenefitsDashboardCompanyBenefitData[]>
    perksBenefits: ComputedRef<AdminBenefitsDashboardCompanyBenefitData[]>
    customBenefits: ComputedRef<CustomBenefit[]>
    tasks: ComputedRef<TaskData[]>
    notices: ComputedRef<{
      expiredMDVPlanOffering: {
        expirationDate: string
        expirationTime: string
        offeringStartDate: string
      } | null
      successfulMDVPlanEnrollment: { openEnrollmentStartDate: string } | null
    }>
  }
  fetching: Ref<boolean>
}>

const adminBenefitsDashboardStoreDefault = {
  data: {
    healthBenefits: computed(
      () => [] as AdminBenefitsDashboardCompanyBenefitData[]
    ),
    wealthBenefits: computed(
      () => [] as AdminBenefitsDashboardCompanyBenefitData[]
    ),
    lifeBenefits: computed(
      () => [] as AdminBenefitsDashboardCompanyBenefitData[]
    ),
    perksBenefits: computed(
      () => [] as AdminBenefitsDashboardCompanyBenefitData[]
    ),
    customBenefits: computed(() => [] as CustomBenefit[]),
    tasks: computed(() => [] as TaskData[]),
    notices: computed(() => ({
      expiredMDVPlanOffering: null,
      successfulMDVPlanEnrollment: null,
    })),
  },
  fetching: ref(true),
}

// function useBffAPI() {
//   const getAdminBenefitsDashboardData = async () => {
//     // TODO: Implement this fetch function
//   }

//   const adminBenefitsDashboardErrors = ref([])
//   const adminBenefitsDashboardStatus = ref(null)

//   return { getAdminBenefitsDashboardData, adminBenefitsDashboardErrors, adminBenefitsDashboardStatus }
// }

export function provideAdminBenefitsDashboardStore() {
  // Legacy API fetch calls

  // Fetch for all company enrollments and offers
  const {
    data: companyBenefits,
    fetching: isFetchingCompanyBenefits,
    get: getCompanyBenefits,
    statusCode: companyBenefitsStatus,
  } = useApiFetchWithInitial<CompanyBenefitsApi>(
    "/benefits_center/benefits/company_benefits",
    {
      initialData: companyBenefitsFetchDefault.companyBenefits.value,
    }
  )

  // Fetch member enrollments to by company to calculate enrollment # for each benefit
  const {
    data: memberEnrollmentsPayload,
    fetching: isFetchingMemberEnrollments,
    get: getMemberEnrollments,
    statusCode: memberEnrollmentsStatus,
  } = useApiFetchWithInitial<MemberEnrollmentAPI>(
    "/benefits_center/benefits/open_member_enrollments_for_company",
    {
      initialData: {
        enrollments: memberEnrollmentsFetchDefault.memberEnrollments.value,
        errors: [],
      },
    }
  )

  // Member enrollments partially come from benreg which can include bad data from terminated members.
  // This will filter out the bad benreg data by checking against a trustworthy list of active members
  const memberEnrollments = computed(() => {
    const memberIds = new Set([
      ...memberDetails.value.map((detail) => detail.benRegId),
    ])

    return memberEnrollmentsPayload.value.enrollments.filter((enrollment) =>
      memberIds.has(enrollment.memberBenregId)
    )
  })
  const memberEnrollmentsErrors = computed(
    () => memberEnrollmentsPayload.value.errors
  )

  // Fetch member details for company
  const {
    data: memberDetails,
    fetching: isFetchingMemberDetails,
    get: getMemberDetails,
    statusCode: memberDetailsStatus,
  } = useApiFetchWithInitial<MemberDetails[]>(
    "/benefits_center/benefits/member_details_for_company",
    {
      initialData: memberDetailsFetchDefault.memberDetails.value,
    }
  )

  // Fetch company quotes
  const {
    data: companyQuotesData,
    fetching: isFetchingCompanyQuotes,
    get: getCompanyQuotes,
  } = useApiFetchWithInitial<QuoteStatuses>("/quote_requests/active", {
    initialData: companyQuotesFetchDefault.companyQuotesData.value,
  })

  // Fetch Unified Plan Selection feature flag
  const {
    data: unifiedPlanSelectionOverviewPayload,
    get: getUnifiedPlanSelectionOverview,
    fetching: isFetchingUnifiedPlanSelectionOverview,
  } = useApiFetchWithInitial<UpsOverview>("/unified_plan_selection/overview", {
    initialData: {
      selection_window: null,
    },
  })
  const unifiedPlanSelectionEnabledPayload = computed(() => {
    return {
      enabled:
        unifiedPlanSelectionOverviewPayload.value.selection_window?.features[
          "unified-plan-selection-overview"
        ] ?? false,
    }
  })

  const isFetchingUnifiedPlanSelectionEnabled = computed(
    () => isFetchingUnifiedPlanSelectionOverview.value
  )

  // Legacy provide calls
  provide(CompanyBenefitsFetchSymbol, {
    getCompanyBenefits,
    isFetchingCompanyBenefits,
    companyBenefits,
    companyBenefitsStatus,
  })
  provide(MemberEnrollmentsFetchSymbol, {
    getMemberEnrollments,
    isFetchingMemberEnrollments,
    memberEnrollments,
    memberEnrollmentsStatus,
    memberEnrollmentsErrors,
  })
  provide(MemberDetailsFetchSymbol, {
    getMemberDetails,
    isFetchingMemberDetails,
    memberDetails,
    memberDetailsStatus,
  })
  provide(CompanyQuotesFetchSymbol, {
    getCompanyQuotes,
    isFetchingCompanyQuotes,
    companyQuotesData,
  })
  provide(UnifiedPlanSelectionOverviewSymbol, {
    getUnifiedPlanSelectionOverview,
    isFetchingUnifiedPlanSelectionOverview,
    unifiedPlanSelectionOverviewPayload,
  })
  provide(UnifiedPlanSelectionEnabledSymbol, {
    getIsUnifiedPlanSelectionEnabled: getUnifiedPlanSelectionOverview,
    isFetchingUnifiedPlanSelectionEnabled,
    unifiedPlanSelectionEnabledPayload,
  })

  // Legacy calculations
  const healthEnrollments = computed(() =>
    companyBenefits.value.enrollments.filter(
      (enrollment) => enrollment.category === "health"
    )
  ) as ComputedRef<CompanyEnrollment[]>

  const upcomingOpenHealthEnrollment = computed(
    () =>
      healthEnrollments.value
        .filter(
          (enrollment) =>
            enrollment.status === "open" &&
            Date.now() < Date.parse(enrollment.enrollmentStartDate)
        )
        // Sort by latest enrollment start date
        .sort(
          (enrollmentA, enrollmentB) =>
            Date.parse(enrollmentA.enrollmentStartDate) -
            Date.parse(enrollmentB.enrollmentStartDate)
        )[0]
  )

  const actionableCompanyBenefitSelection = computed(() =>
    healthEnrollments.value.find(
      (enrollment) => enrollment.companyOfferingStatus === "actionable"
    )
  )

  const expiredCompanyBenefitPeriod = computed(() =>
    healthEnrollments.value.find(
      (enrollment) => enrollment.companyOfferingStatus === "expired"
    )
  )

  const beforeOpenEnrollmentStartDate = computed(() => {
    const openEnrollmentStartDate =
      upcomingOpenHealthEnrollment.value?.openEnrollmentStartDate

    return (
      openEnrollmentStartDate &&
      Date.now() < Date.parse(openEnrollmentStartDate)
    )
  })

  const offeringTypes = computed(() => {
    const offeringTypeOptions = healthEnrollments.value
      .filter((enrollment) => enrollment.status === "pending")
      .map((enrollment) => enrollment.benefitType)

    if (offeringTypeOptions.length === 1) {
      return offeringTypeOptions[0]
    }

    if (offeringTypeOptions.length === 2) {
      return offeringTypeOptions.join(" and ")
    }

    const last = offeringTypeOptions.pop()

    return offeringTypeOptions.join(", ") + ` and ${last}`
  })

  const notices = computed(() => {
    let expiredMDVPlanOffering = null
    let successfulMDVPlanEnrollment = null

    if (expiredCompanyBenefitPeriod.value) {
      expiredMDVPlanOffering = {
        expirationDate:
          expiredCompanyBenefitPeriod.value?.openEnrollmentStartDate,
        expirationTime: "11:59pm EST",
        offeringStartDate: expiredCompanyBenefitPeriod.value?.offeringStartDate,
      }
    }

    if (
      upcomingOpenHealthEnrollment.value &&
      beforeOpenEnrollmentStartDate.value
    ) {
      successfulMDVPlanEnrollment = {
        enrollmentStartDate: formatDateFull(
          upcomingOpenHealthEnrollment.value?.enrollmentStartDate
        ),
        openEnrollmentStartDate: formatDateFull(
          upcomingOpenHealthEnrollment.value?.openEnrollmentStartDate
        ),
      }
    }

    return {
      expiredMDVPlanOffering,
      successfulMDVPlanEnrollment,
    }
  })

  // BFF data shim calculations
  function activeEnrolledMembers(benefit: {
    enrollmentStartDate: string
    benefitType: string
  }) {
    if (isFetchingMemberEnrollments.value) return undefined

    const isPending = Date.parse(benefit.enrollmentStartDate) > Date.now()

    if (isPending) {
      return memberEnrollments.value.filter(
        (enrollment) =>
          Date.parse(enrollment.enrollmentStartDate) > Date.now() &&
          enrollment.benefitType === benefit.benefitType
      ).length
    }

    return memberEnrollments.value.filter(
      (enrollment) =>
        Date.parse(enrollment.enrollmentStartDate) <= Date.now() &&
        enrollment.benefitType === benefit.benefitType
    ).length
  }

  const healthBenefits = computed(() => {
    // Only render 'confirmed' health enrollments, not 'new' enrollments in admin plan selection
    return companyBenefits.value.enrollments
      .filter((benefit) => {
        return (
          benefit.category === "health" &&
          [
            BenefitType.MEDICAL,
            BenefitType.DENTAL,
            BenefitType.VISION,
          ].includes(benefit.benefitType) &&
          benefit?.companyOfferingStatus === "confirmed"
        )
      })
      .map((enrollment) => ({
        ...enrollment,
        numberOfEnrolledMembers: activeEnrolledMembers(enrollment),
      })) as AdminBenefitsDashboardCompanyBenefitData[]
  })

  const wealthBenefits = computed(() => {
    return companyBenefits.value.enrollments
      .filter((benefit) => benefit.category === "wealth")
      .map((enrollment) => ({
        ...enrollment,
        numberOfEnrolledMembers: activeEnrolledMembers(enrollment),
      })) as AdminBenefitsDashboardCompanyBenefitData[]
  })

  const lifeBenefits = computed(() => {
    return companyBenefits.value.enrollments
      .filter((benefit) => benefit.category === "life")
      .map((enrollment) => ({
        ...enrollment,
        numberOfEnrolledMembers: activeEnrolledMembers(enrollment),
      })) as AdminBenefitsDashboardCompanyBenefitData[]
  })

  // TODO: Implement Perks Benefits
  const perksBenefits = computed(() => [])

  const customBenefits = computed(() => {
    return companyBenefits.value.enrollments
      .filter((enrollment) => enrollment.benefitType === BenefitType.CUSTOM)
      .sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      )
  })

  const tasks = computed(() => {
    const currentTasks: TaskData[] = []

    if (
      isFetchingCompanyBenefits.value ||
      isFetchingCompanyQuotes.value ||
      isFetchingUnifiedPlanSelectionEnabled.value
    ) {
      return currentTasks
    }

    const showQuoteTask = !!(
      actionableCompanyBenefitSelection.value &&
      companyQuotesData.value[0]?.status &&
      companyQuotesData.value[0]?.id
    )
    const showUPSPlanSelectionTask = !!(
      actionableCompanyBenefitSelection.value &&
      unifiedPlanSelectionEnabledPayload.value.enabled &&
      !showQuoteTask
    )
    const showPlanSelectionTask = !!(
      actionableCompanyBenefitSelection.value &&
      !showUPSPlanSelectionTask &&
      !showQuoteTask
    )
    const showCarrierInstallDocTask = !!upcomingOpenHealthEnrollment.value

    if (showQuoteTask) {
      currentTasks.push({
        id: "mdv-quote-task",
        title: "Check out plans and pricing",
        description:
          "View your quote and set up your company’s health coverage.",
        route: `/nextben/admin/benefits/health/quote/view-quote/${companyQuotesData.value[0]?.id}`,
      })
    }

    if (showUPSPlanSelectionTask) {
      currentTasks.push({
        id: "mdv-unified-plan-selection-task",
        title: "Check out plans and pricing",
        description: `Set your company's ${
          offeringTypes.value
        } offerings by ${formatDateMedium(
          actionableCompanyBenefitSelection.value?.openEnrollmentStartDate
        )}`,
        route: `${import.meta.env.VITE_NEON_DASH_URL}/company/${
          viewer.value.company.neonsky_id
        }/benefits/health/plan-selection`,
      })
    }

    if (showPlanSelectionTask) {
      currentTasks.push({
        id: "mdv-plan-selection-task",
        title: `Check out plans and pricing`,
        description: `Set your company's ${offeringTypes.value}
        offerings by ${formatDateMedium(
          actionableCompanyBenefitSelection.value?.openEnrollmentStartDate
        )}`,
        route: {
          name: "admin-health-enrollment-acknowledgment",
          params: {
            offeringStartDate:
              actionableCompanyBenefitSelection.value?.offeringStartDate,
          },
        },
      })
    }

    if (showCarrierInstallDocTask) {
      currentTasks.push({
        id: "mdv-carrier-install-doc-task",
        title: "Carrier Install Documents",
        description:
          "Have you uploaded your documents yet? Carriers require documents in order to finalize your enrollments.",
        route: {
          name: "admin-health-enrollment-documents-carrier-install",
          params: {
            offeringStartDate:
              upcomingOpenHealthEnrollment.value?.offeringStartDate,
          },
        },
        buttonLabel: "Upload documents",
      })
    }

    return currentTasks
  })

  const fetching = computed(
    () =>
      isFetchingCompanyBenefits.value ||
      isFetchingMemberEnrollments.value ||
      isFetchingMemberDetails.value ||
      isFetchingCompanyQuotes.value ||
      isFetchingUnifiedPlanSelectionEnabled.value
  )

  // If BFF flag is enabled, use the BFF API fetch data instead
  const adminBenefitsDashboardStore = {
    data: {
      healthBenefits,
      wealthBenefits,
      lifeBenefits,
      perksBenefits,
      customBenefits,
      tasks,
      notices,
    },
    fetching,
  }

  // const { getAdminBenefitsDashboardData, adminBenefitsDashboardErrors, adminBenefitsDashboardStatus } = useBffAPI()

  // Admin Benefits Dashboard data store
  provide(AdminBenefitsDashboardStoreSymbol, adminBenefitsDashboardStore)
}

// Hook function to access the data
export function useAdminBenefitsDashboardStore() {
  return inject(
    AdminBenefitsDashboardStoreSymbol,
    adminBenefitsDashboardStoreDefault
  )
}
