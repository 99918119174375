// See BSMG-3163
/* eslint-disable @typescript-eslint/no-misused-promises */
import { ref, watch } from "vue"
import { useApiFetchWithInitial, useGlobalError } from "stella"
import { useDebounceFn } from "@vueuse/core"
import { loginRedirect } from "./router"
import { viewerDefault } from "./symbols"
import type { Viewer } from "./types"

const loaded = ref(false)
const viewer = ref<Viewer>(viewerDefault.value)
const isAdmin = ref(false)

const { setGlobalError } = useGlobalError(
  // Vite URL injected via secrets in github actions
  `${import.meta.env.VITE_PAYROLL_PROVIDER_URL}`
)

const {
  data,
  get: getViewer,
  fetching,
  error,
  statusCode,
} = useApiFetchWithInitial<Viewer>("/viewer", {
  initialData: viewerDefault.value,
})

const debouncedGetViewer = useDebounceFn(() => {
  // TODO: Remove the approvals condition when we're done with the selection setup work for admins
  // and deprecate the admin approvals page feature.
  // Ticket: https://justworks.atlassian.net/browse/BSMG-2037
  // The login callback page is also excluded because calling the viewer will interfere with the
  // login callback flow.
  if (
    window.location.pathname !== "/approvals" &&
    window.location.pathname !== "/login/callback" &&
    window.location.pathname !== "/nextben/setup"
  )
    void getViewer()
}, 500)

function isEmployeeRestricted(routeAbilities?: string[]) {
  if (!routeAbilities) {
    return false
  } else {
    return !routeAbilities.some((ability) =>
      viewer.value?.member?.abilities?.includes(ability)
    )
  }
}

window.addEventListener("focus", debouncedGetViewer)
watch(data, () => {
  if (data.value) {
    viewer.value = data.value
    window.sessionStorage.setItem(
      "target_member_id",
      viewer.value.member.canonical_id
    )
    isAdmin.value = data.value.member.abilities.includes(
      "manage_company_benefits"
    )
    if (
      data.value.member.status == "not_imported" &&
      window.location.pathname !== "/setup"
    ) {
      loginRedirect("/setup")
    }
  } else {
    // TODO - This does not trigger a redirect like we thought it did
    setGlobalError(401)
  }
  loaded.value = true
})

/**
 * Login redirect logic specifically for the initial viewer call. Does not redirect from useApiFetch from other files
 * Global login redirect coming in a separate PR
 */
watch(statusCode, () => {
  if (statusCode.value === 401) {
    loginRedirect(viewer.value?.member.back_button_routing_url)
  }
})

export {
  error,
  fetching,
  getViewer,
  isEmployeeRestricted,
  loaded,
  viewer,
  isAdmin,
}
